
body {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    /* overflow-y: auto; */
    overflow-x: hidden;
  }
  
  #root {
    width: 100%;
    height: 100%;
  }
  
  /* Global styles */
  /* For Webkit browsers like Chrome, Safari */
  ::-webkit-scrollbar {
    width: 12px;
    border-radius: 16px;
  }
  
  ::-webkit-scrollbar-track {
    background: #eee;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #BDBDBD;
    height: 44px;
    border-radius: 16px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#555, #333);
    border-radius: 64px;
  }
  
  * {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #9fcee0 #eee;
  }
  
.MuiDivider-root::before {
  width: 0% !important;
}